import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import Container from '../../../primitives/grid/container';
import Row from '../../../primitives/grid/row';
import Col from '../../../primitives/grid/col';
import CustomStructuredTextRenderer from '../../custom-structured-text-renderer';
import Typography from '../../../primitives/typography';
import { colors } from '../../../primitives/colors';
import { useLanguage } from '../../../language/language-provider';
import Star from './star.svg';
import Link from '../../../primitives/link';
import { breakpoints } from '../../../primitives/tokens';

export type PoliciesListProps = Queries.PoliciesListPropsFragment;

const PoliciesList = ({
    richTitle,
    text,
}: PoliciesListProps): JSX.Element => {
    const { language } = useLanguage();
    const staticData = useStaticQuery<Queries.PoliciesDataQuery>(graphql`
        query PoliciesData {
            allDatoCmsPolicy {
                nodes {
                    title
                    regions
                    description {
                        blocks
                        links
                        value
                    }
                    starred
                    policyLastUpdated
                    permalink
                }
            }
        }
    `);

    const {
        nodes: policies,
    } = staticData.allDatoCmsPolicy;

    return (
        <Container css={{
            marginTop: '140px',
            marginBottom: '80px',
        }}
        >
            <Row>
                <Col breakpoints={{
                    dt: { span: 6 },
                }}
                >
                    <Typography
                        fontSize={{
                            dt: 4053,
                            tb: 3234,
                            mb: 2429,
                        }}
                        override
                        fontWeight="light"
                    >
                        <CustomStructuredTextRenderer data={richTitle} />
                    </Typography>
                    <Typography
                        fontSize={{
                            dt: 1322,
                        }}
                        css={{
                            paddingTop: '12px',
                        }}
                        override
                    >
                        {text}
                    </Typography>
                </Col>
                <Col breakpoints={{
                    dt: { span: 6 },
                }}
                >
                    <ul
                        css={{
                            gap: '30px',
                            display: 'flex',
                            flexDirection: 'column',
                            listStyleType: 'none',
                            [breakpoints.mb]: {
                                paddingTop: '32px',
                            },
                        }}
                    >
                        {policies && policies.filter(x => x.regions.includes(language)).map(item => (
                            <li css={{
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'space-between',
                                borderBottom: `1px solid ${colors.grey100}`,
                                paddingBottom: '30px',
                            }}
                            >
                                <Link to={item.permalink}>
                                    <Typography
                                        fontSize={{
                                            dt: 1822,
                                        }}
                                        color="primaryOrange"
                                        fontWeight="medium"
                                        override
                                        css={{
                                            display: 'inline-flex',
                                            gap: '10px',
                                            alignItems: 'center',
                                        }}
                                    >
                                        {item.starred && (
                                            <>
                                                <Star css={{
                                                    width: '16px',
                                                    height: '16px',
                                                }}
                                                />
                                                {' '}
                                            </>
                                        )}
                                        {item.title}
                                    </Typography>
                                    <Typography
                                        fontSize={{
                                            dt: 1420,
                                        }}
                                        css={{
                                            marginTop: '8px',
                                        }}
                                        override
                                    >
                                        <CustomStructuredTextRenderer data={item.description} />
                                    </Typography>
                                    <Typography
                                        fontSize={{
                                            dt: 1627,
                                            tb: 1420,
                                            mb: 1226,
                                        }}
                                        css={{
                                            marginTop: '8px',
                                            fontStyle: 'italic',
                                        }}
                                        override
                                    >
                                        Last updated
                                        {' '}
                                        {item.policyLastUpdated}
                                    </Typography>
                                </Link>
                            </li>
                        ))}
                    </ul>
                </Col>
            </Row>
        </Container>
    );
};

export default PoliciesList;

export const query = graphql`
    fragment PoliciesListProps on DatoCmsPoliciesList {
        richTitle {
            blocks
            links
            value
        }
        text
    }
`;
